import { useState } from "react";
import { createContainer } from "unstated-next";
import { IndustryCompaniesCountQueryResult } from "../dashboard-api";
import { ChartType } from "../types";

export const useDashboardStore = () => {
  const [selectedChartType, setSelectedChartType] = useState<ChartType>("graph");
  const [industryCompaniesCounts, setIndustryCompaniesCounts] = useState<IndustryCompaniesCountQueryResult[]>([]);
  const [allIndustryCompaniesCounts, setAllIndustryCompaniesCounts] = useState<IndustryCompaniesCountQueryResult[]>([]);
  const [displaySwitchDisabled, setDisplaySwitchDisabled] = useState(true);
  const [formDirty, setFormDirty] = useState(false);

  return {
    selectedChartType,
    setSelectedChartType,
    // Board
    industryCompaniesCounts,
    setIndustryCompaniesCounts,
    allIndustryCompaniesCounts,
    setAllIndustryCompaniesCounts,
    displaySwitchDisabled,
    setDisplaySwitchDisabled,
    formDirty,
    setFormDirty,
  };
};

export const DashboardStore = createContainer(useDashboardStore);
