import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TFunction } from "i18next";
import { EnqueueSnackbar } from "notistack";

import { ReactNode } from "react";
import { NavigateFunction } from "react-router-dom";
import { CloseSnackbarButton } from "../../components/close-snackbar-button";
import { REFETCH_INTERVAL, STALE_TIME } from "../../config/const";
import { axiosInstance } from "../../lib/axios";
import { MAX_RETRIES, queryClient } from "../../lib/react-query";
import { Chart, CompanyType, IndustryDivision, IndustryDivisionOrAll, LastEvaluatedKey } from "../../types";
import { BoardVo, BusinessYearVo, CompanyVo, ExportJobVo, TenantVo, UserVo } from "../../types/vo";
import { handleClientError } from "../../utils/handle-client-error";
import { snackbarUtil } from "../../utils/snackbar";
import { DashboardChartFilterFormModel } from "./components/dashboard-chart-filter-form";
import { getCompaniesCount, supplyAveragesInChart } from "./data/industry-averages";
import { BaseData, CalcBasisBaseData, CalcBasisItem, ChartGroup, ChartIndicator, Unit } from "./types";

export type BusinessYearQueryResult = BusinessYearVo;

export type SettingQueryResult = TenantVo;

export type CompanyQueryResult = CompanyVo;

export type CurrentUserQueryResult = UserVo;

export type DashboardBoardQueryResult = BoardVo;

export type DashboardCustomBoardsCreateRequest = {
  name: string;
};

export type DashboardCustomBoardNameUpdateRequest = {
  id: string;
  name: string;
};

export type DashboardCustomBoardChartsUpdateRequest = {
  id: string;
  groups: ChartGroup[];
};

export type DashboardChartGetRequest = {
  fromBusinessYearStartDate: string;
  toBusinessYearStartDate: string;
  companyType: CompanyType;
  companyIds: string[];
  industryDivision: IndustryDivision;
};

export type DashboardExportSubmitRequest = {
  boardId: string;
  charts: Chart[];
} & DashboardChartGetRequest;

export type FilterQueryResult = {
  businessYears: BusinessYearVo[];
  companies: CompanyVo[];
};

export type ChartQueryResult<TData extends BaseData> = {
  chartIndicator: ChartIndicator;
  unit: Unit;
  datasets: {
    index: string; // 事業年度（2023年度）や年齢分布（20-24）など、データセットをまとめる単位
    data: TData[];
  }[];
};

export type CalcBasisQueryResult<TData extends CalcBasisBaseData> = {
  calcBasisItem: CalcBasisItem;
  unit: Unit;
  datasets: {
    index: string; // 事業年度（2023年度）や年齢分布（20-24）など、データセットをまとめる単位
    data: TData[];
  }[];
};

export type IndustryCompaniesCountGetRequest = {
  fromBusinessYearStartDate: string;
  toBusinessYearStartDate: string;
  industryOrAll: IndustryDivisionOrAll;
};

export type IndustryCompaniesCountQueryResult = {
  year: number;
  count: number | null;
};

export type ExportJobQueryResult = {
  items: ExportJobVo[];
  lastEvaluatedKey: Record<string, Record<string, unknown>> | null;
};

// axios

const getBusinessYears = async (): Promise<BusinessYearQueryResult[]> => {
  return axiosInstance.get(`/business-years`);
};

const getCompanies = async (): Promise<CompanyQueryResult[]> => {
  return axiosInstance.get(`/companies`);
};

const getSetting = async (): Promise<SettingQueryResult> => {
  return axiosInstance.get("/setting");
};

const getBoards = async (): Promise<DashboardBoardQueryResult[]> => {
  return axiosInstance.get(`/dashboard/boards/presets`);
};

const getCustomBoards = async (): Promise<DashboardBoardQueryResult[]> => {
  return axiosInstance.get(`/dashboard/boards/customs`);
};

const getBoard = async (id: string): Promise<DashboardBoardQueryResult> => {
  return axiosInstance.get(`/dashboard/boards/${id}`);
};

const createCustomBoard = async (data: DashboardCustomBoardsCreateRequest) => {
  return axiosInstance.post(`/dashboard/boards/customs`, data);
};

const updateCustomBoardName = async (data: DashboardCustomBoardNameUpdateRequest) => {
  return axiosInstance.put(`/dashboard/boards/customs/name`, data);
};

const updateCustomBoardCharts = async (data: DashboardCustomBoardChartsUpdateRequest) => {
  return axiosInstance.put(`/dashboard/boards/customs/charts`, data);
};

const deleteCustomBoard = async (id: string): Promise<boolean> => {
  return axiosInstance.delete(`/dashboard/boards/${id}`);
};

const getCurrentUser = (): Promise<CurrentUserQueryResult> => {
  return axiosInstance.get(`/users/current`);
};

const getCharts = async <TData extends BaseData>(
  chart: Chart,
  filter: DashboardChartGetRequest,
  signal?: AbortSignal
): Promise<ChartQueryResult<TData> | ChartQueryResult<TData>[]> => {
  const result: ChartQueryResult<TData> = await axiosInstance.get(`/dashboard/charts/${chart}`, {
    params: filter,
    signal,
  });
  return supplyAveragesInChart(filter.industryDivision, result);
};

const getFilters = async (chart: Chart): Promise<FilterQueryResult> => {
  return axiosInstance.get(`/dashboard/charts/${chart}/filters`);
};

const getCalcBases = async <TData extends CalcBasisBaseData>(
  chart: Chart,
  filter: DashboardChartGetRequest
): Promise<CalcBasisQueryResult<TData> | CalcBasisQueryResult<TData>[]> => {
  const result: CalcBasisQueryResult<TData> = await axiosInstance.get(`/dashboard/charts/${chart}/calc-bases`, {
    params: filter,
  });
  return result;
};

const getFiltersInBoard = async (): Promise<FilterQueryResult> => {
  return axiosInstance.get(`/dashboard/charts/gender_pay_gap/filters`);
};

// TODO: 以下のAPIを実装する
// パス: /dashboard/charts/${chart}/companies/count?industry={業種IDかall}&fromBusinessYearStartDate={事業年度開始日}&toBusinessYearStartDate={事業年度開始日}
// 返り値
// [
//   {
//     year: number, // 2023年
//     count: number
//   }
// ]
const getIndustryCompaniesCount = async (
  chart: Chart,
  fromBusinessYearStartDate: string,
  toBusinessYearStartDate: string,
  companyType: CompanyType,
  companyIds: string[],
  industryOrAll: IndustryDivisionOrAll = "all"
): Promise<IndustryCompaniesCountQueryResult[]> => {
  const count = getCompaniesCount(chart, industryOrAll);
  if (count || industryOrAll === "all") {
    const fromYear = Number(fromBusinessYearStartDate.slice(0, 4));
    const toYear = Number(toBusinessYearStartDate.slice(0, 4));

    const results: IndustryCompaniesCountQueryResult[] = [];
    for (let year = fromYear; year <= toYear; year++) {
      results.push({ year, count });
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(results);
      }, 100);
    });
  } else {
    const filter: DashboardChartGetRequest = {
      fromBusinessYearStartDate,
      toBusinessYearStartDate,
      companyType,
      companyIds,
      industryDivision: industryOrAll,
    };
    return await axiosInstance.get(`/dashboard/charts/${chart}/companies/count`, {
      params: filter,
    });
  }
};

const getExportJobs = async (
  lastEvaluatedKey: LastEvaluatedKey | null,
  pageSize: number
): Promise<ExportJobQueryResult> => {
  const lastEvaluatedKeyString = lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : null;
  return axiosInstance.get(`/export-jobs`, { params: { lastEvaluatedKeyString, pageSize } });
};

const countExportJobs = async (): Promise<number> => {
  return axiosInstance.get(`/export-jobs/count`);
};

const getExportJobsDownloadUrl = async (exportJobId: string, fileName: string): Promise<string> => {
  return axiosInstance.get(`/export-jobs/download-url`, {
    params: { exportJobId, fileName },
  });
};

const submitExport = async (data: DashboardExportSubmitRequest) => {
  return axiosInstance.post(`/dashboard/export`, data);
};

// react-query

const useBusinessYears = () => {
  return useQuery({ queryKey: ["@dashboard", "business-years"], queryFn: getBusinessYears });
};

const useCompanies = () => {
  return useQuery({ queryKey: ["companies"], queryFn: getCompanies });
};

const useSetting = () => {
  return useQuery({ queryKey: ["@dashboard", "setting"], queryFn: getSetting });
};

const useBoards = () => {
  return useQuery({ queryKey: ["dashboard", "boards", "presets"], queryFn: () => getBoards() });
};

const useCustomBoards = () => {
  return useQuery({ queryKey: ["dashboard", "boards", "customs"], queryFn: () => getCustomBoards() });
};

const useCreateCustomBoard = (t: TFunction, enqueSnackbar: EnqueueSnackbar, navigate: NavigateFunction) => {
  return useMutation({
    mutationFn: createCustomBoard,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["dashboard", "boards", "customs"] });
      navigate(`/boards/${data}`);
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useUpdateCustomBoardName = (t: TFunction, enqueSnackbar: EnqueueSnackbar, id: string) => {
  return useMutation({
    mutationFn: updateCustomBoardName,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["dashboard", "boards", id] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useUpdateCustomBoardCharts = (t: TFunction, enqueSnackbar: EnqueueSnackbar, id: string) => {
  return useMutation({
    mutationFn: updateCustomBoardCharts,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["dashboard", "boards", id] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useDeleteCustomBoard = (t: TFunction, enqueSnackbar: EnqueueSnackbar, startDate: string) => {
  return useMutation({
    mutationFn: () => deleteCustomBoard(startDate),
    onSuccess: (data: boolean) => {
      if (data) {
        queryClient.invalidateQueries({ queryKey: ["dashboard", "boards", "customs"] });
        enqueSnackbar(t("snackbar.deleted"), { variant: "success" });
      } else {
        const message = t("snackbar.dashboard.boards.not-deletable");
        enqueSnackbar(message, {
          autoHideDuration: snackbarUtil.AUTO_HIDE_DURATION_LONG,
          action: CloseSnackbarButton,
          style: { width: snackbarUtil.getCssWidthInPixel(message) },
        });
      }
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useBoard = (id: string) => {
  return useQuery({ queryKey: ["dashboard", "boards", id], queryFn: () => getBoard(id) });
};

const useCurrentUser = () => {
  return useQuery({ queryKey: ["@dashboard", "users/current"], queryFn: () => getCurrentUser() });
};

const useCharts = <TData extends BaseData>(
  chart: Chart,
  filter?: DashboardChartFilterFormModel
) => {
  return useQuery({
    queryKey: ["dashboard", "charts", chart, filter],
    // カスタムボードでチェックボックスをONにしてチャートがロードされている最中に、
    // チェックボックスをOFFにした場合に、リクエストをキャンセルするためにsignalを利用している
    // 公式: https://tanstack.com/query/v4/docs/framework/react/guides/query-cancellation
    queryFn: ({ signal }) => getCharts<TData>(chart, filter as DashboardChartFilterFormModel, signal),
    enabled: Boolean(filter),
    staleTime: STALE_TIME,
    retry: MAX_RETRIES,
  });
};

const useFilters = (chart: Chart) => {
  return useQuery({
    queryKey: ["dashboard", "charts", chart, "filters"],
    queryFn: () => getFilters(chart),
  });
};

const useCalcBases = <TData extends CalcBasisBaseData>(chart: Chart, filter?: DashboardChartFilterFormModel) => {
  return useQuery({
    queryKey: ["dashboard", "charts", chart, "calc-bases", filter],
    queryFn: () => getCalcBases<TData>(chart, filter as DashboardChartFilterFormModel),
    enabled: Boolean(filter),
    staleTime: STALE_TIME,
    retry: MAX_RETRIES,
  });
};

const useFiltersInBoard = () => {
  return useQuery({
    queryKey: ["dashboard", "charts", "filters"],
    queryFn: () => getFiltersInBoard(),
  });
};

const useIndustryCompaniesCount = (
  chart?: Chart,
  fromBusinessYearStartDate?: string,
  toBusinessYearStartDate?: string,
  companyType?: CompanyType,
  companyIds?: string[],
  IndustryDivision?: IndustryDivision
) => {
  return useQuery({
    queryKey: [
      "dashboard",
      "charts",
      chart,
      "companies",
      "count",
      fromBusinessYearStartDate,
      toBusinessYearStartDate,
      companyType,
      companyIds,
      IndustryDivision,
    ],
    queryFn: () =>
      getIndustryCompaniesCount(
        chart as Chart,
        fromBusinessYearStartDate as string,
        toBusinessYearStartDate as string,
        companyType as CompanyType,
        companyIds as string[],
        IndustryDivision as IndustryDivision
      ),
    enabled:
      Boolean(chart) &&
      Boolean(fromBusinessYearStartDate) &&
      Boolean(toBusinessYearStartDate) &&
      Boolean(companyType) &&
      Boolean(IndustryDivision),
  });
};

const useAllIndustryCompaniesCount = (
  chart: Chart,
  fromBusinessYearStartDate?: string,
  toBusinessYearStartDate?: string,
  companyType?: CompanyType,
  companyIds?: string[]
) => {
  return useQuery({
    queryKey: ["dashboard", "charts", chart, "companies", "count", fromBusinessYearStartDate, toBusinessYearStartDate],
    queryFn: () =>
      getIndustryCompaniesCount(
        chart,
        fromBusinessYearStartDate as string,
        toBusinessYearStartDate as string,
        companyType as CompanyType,
        companyIds as string[]
      ),
    enabled: Boolean(fromBusinessYearStartDate) && Boolean(toBusinessYearStartDate) && Boolean(companyType),
  });
};

const useExportJobs = (lastEvaluatedKey: LastEvaluatedKey | null, pageSize: number) => {
  return useQuery({
    queryKey: ["export-jobs", lastEvaluatedKey, pageSize],
    queryFn: () => getExportJobs(lastEvaluatedKey, pageSize),
    refetchInterval: REFETCH_INTERVAL,
  });
};

const useExportJobsCount = () => {
  return useQuery({
    queryKey: ["export-jobs/count"],
    queryFn: () => countExportJobs(),
    refetchInterval: REFETCH_INTERVAL,
  });
};

const useExportJobsDownloadUrl = (exportJobId: string, fileName: string) => {
  return useQuery({
    queryKey: ["export-jobs/download-url", exportJobId, fileName],
    queryFn: () => getExportJobsDownloadUrl(exportJobId, fileName),
    enabled: false,
  });
};

const useSubmitExport = (t: TFunction, enqueSnackbar: EnqueueSnackbar, message: ReactNode) => {
  return useMutation({
    mutationFn: submitExport,
    onSuccess: () => {
      enqueSnackbar(message, {
        variant: "success",
      });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

export const dashboardApi = {
  useBusinessYears,
  useCompanies,
  useSetting,
  useBoards,
  useCustomBoards,
  useCreateCustomBoard,
  useUpdateCustomBoardName,
  useUpdateCustomBoardCharts,
  useDeleteCustomBoard,
  useBoard,
  useCurrentUser,
  useCharts,
  useFilters,
  useCalcBases,
  useFiltersInBoard,
  useIndustryCompaniesCount,
  useAllIndustryCompaniesCount,
  useExportJobs,
  useExportJobsCount,
  useExportJobsDownloadUrl,
  useSubmitExport,
};
