import CalculateIcon from "@mui/icons-material/Calculate";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import Highcharts from "highcharts";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import HighchartsReact from "highcharts-react-official";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/loading/loading";
import { MoreMenu } from "../../../components/more-menu";
import { StyledAccordion } from "../../../components/styled/styled-accordion";
import { StyledAccordionDetails } from "../../../components/styled/styled-accordion-detail";
import { StyledAccordionSummary } from "../../../components/styled/styled-accordion-summary";
import { ChartTextDef } from "../../../config/text-def";
import { Chart } from "../../../types";
import { IBaseChart } from "../classes/i-base-chart";
import { CHART_HEIGHT_IN_BOARD, SINGLE_YEAR_CHARTS, NO_FORMULA_CHARTS, CHART_MARGIN_BOTTOM } from "../config/const";
import { dashboardApi } from "../dashboard-api";
import { DashboardStore } from "../stores/dashboard-store";
import { BaseData, ChartType } from "../types";
import { DashboardChartDetailLink } from "./dashboard-chart-detail-link";
import { DashboardChartDisplaySwitchModel } from "./dashboard-chart-display-switch";
import { DashboardChartFilterFormModel } from "./dashboard-chart-filter-form";
import { DashboardFormula } from "./dashboard-formula";
import { DashboardTable } from "./dashboard-table";

NoDataToDisplay(Highcharts); // ReactだとこれがないとnoDataプロパティが効かない

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: CHART_HEIGHT_IN_BOARD + CHART_MARGIN_BOTTOM,
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
}));
StyledPaper.defaultProps = {
  square: true,
};

type DashboardChartInBoardProps<TData extends BaseData, TChartClass extends IBaseChart<TData>> = {
  chartInstance: TChartClass;
  chart: Chart;
  type: ChartType;
  filter?: DashboardChartFilterFormModel;
  displaySwitch: DashboardChartDisplaySwitchModel;
  onLoaded: (chart: Chart) => void;
};

const _DashboardChartInBoard = <TData extends BaseData, TChartClass extends IBaseChart<TData>>({
  chartInstance,
  chart,
  type,
  filter,
  displaySwitch,
  onLoaded,
}: DashboardChartInBoardProps<TData, TChartClass>) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [noData, setNoData] = useState(true);
  const [formulaOpen, setFormulaOpen] = useState(false);
  const { setIndustryCompaniesCounts, setAllIndustryCompaniesCounts, setDisplaySwitchDisabled } =
    DashboardStore.useContainer();
  const useSettingsQuery = dashboardApi.useSetting();
  const useChartsQuery = dashboardApi.useCharts<TData>(chart, filter);
  const useIndustryCompaniesCountQuery = dashboardApi.useIndustryCompaniesCount(
    chart,
    filter?.fromBusinessYearStartDate,
    filter?.toBusinessYearStartDate,
    filter?.companyType,
    filter?.companyIds,
    filter?.industryDivision
  );
  const useAllIndustryCompaniesCountQuery = dashboardApi.useAllIndustryCompaniesCount(
    chart,
    filter?.fromBusinessYearStartDate,
    filter?.toBusinessYearStartDate,
    filter?.companyType,
    filter?.companyIds
  );
  // チャート一覧の年齢分布の横に最新年度を表示するため
  const useBusinessYearsQuery = dashboardApi.useBusinessYears();

  useEffect(() => {
    if (!useSettingsQuery.isLoading && !useChartsQuery.isLoading && !useChartsQuery.isFetching) {
      onLoaded(chart);
    }
  }, [useSettingsQuery.isLoading, useChartsQuery.isLoading, useChartsQuery.isFetching, chart, onLoaded]);

  useEffect(() => {
    if (useIndustryCompaniesCountQuery.data) {
      setIndustryCompaniesCounts((prev) => [...prev, ...useIndustryCompaniesCountQuery.data]);
    }
  }, [useIndustryCompaniesCountQuery.data, setIndustryCompaniesCounts]);

  useEffect(() => {
    if (useAllIndustryCompaniesCountQuery.data) {
      setAllIndustryCompaniesCounts((prev) => [...prev, ...useAllIndustryCompaniesCountQuery.data]);
    }
  }, [useAllIndustryCompaniesCountQuery.data, setAllIndustryCompaniesCounts]);

  useEffect(() => {
    if (useChartsQuery.data) {
      const noData = chartInstance.noData(useChartsQuery.data);
      setNoData(noData);
      setDisplaySwitchDisabled((prev) => prev && noData);
    }
  }, [useChartsQuery.data, setDisplaySwitchDisabled, chartInstance]);

  const onClickFormula = () => {
    setFormulaOpen(true);
  };

  const renderLoadingGraph = () => (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" my={1}>
        <Typography>{t(ChartTextDef.get(chart) as string)}</Typography>
      </Box>
      <Divider />
      <StyledPaper>
        <Loading my={16} />
      </StyledPaper>
    </>
  );

  const renderLoadingTable = () => (
    <StyledAccordion defaultExpanded sx={{ boxShadow: "none" }}>
      <StyledAccordionSummary my={0}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>{t(ChartTextDef.get(chart) as string)}</Typography>
          </Grid>
        </Grid>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Box mt={-4} display="flex" justifyContent="center" alignItems="center">
          <Loading my={1} />
        </Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );

  if (
    useSettingsQuery.isLoading ||
    useChartsQuery.isLoading ||
    useChartsQuery.isFetching ||
    useBusinessYearsQuery.isLoading
  )
    return type === "graph" ? renderLoadingGraph() : renderLoadingTable();

  if (!useSettingsQuery.data || !useChartsQuery.data || !useBusinessYearsQuery.data) return null;

  const renderGraph = () => {
    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>
            {t(ChartTextDef.get(chart) as string) +
              (SINGLE_YEAR_CHARTS.includes(chart)
                ? " ※" +
                  useBusinessYearsQuery.data.find((year) => year.startDate === filter?.toBusinessYearStartDate)?.name
                : "")}
          </Typography>
          <MoreMenu>
            <MenuItem>
              <DashboardChartDetailLink noData={noData} chart={chart} />
            </MenuItem>
            <MenuItem onClick={onClickFormula} disabled={NO_FORMULA_CHARTS.includes(chart)}>
              <CalculateIcon
                sx={{
                  color: (theme) => theme.palette.grey[600],
                  mr: 1,
                }}
              />
              {t("view.formula")}
            </MenuItem>
          </MoreMenu>
        </Box>
        <Divider />
        <StyledPaper>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartInstance.getChartOptions(t, useChartsQuery.data, displaySwitch, true)}
            ref={chartComponentRef}
          />
          <DashboardFormula open={formulaOpen} onClose={() => setFormulaOpen(false)} chart={chart} />
        </StyledPaper>
      </>
    );
  };

  const renderTable = () => {
    return (
      <StyledAccordion defaultExpanded sx={{ boxShadow: "none" }}>
        <StyledAccordionSummary my={0}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography>
                {t(ChartTextDef.get(chart) as string) +
                  (SINGLE_YEAR_CHARTS.includes(chart)
                    ? " ※" +
                      useBusinessYearsQuery.data.find((year) => year.startDate === filter?.toBusinessYearStartDate)
                        ?.name
                    : "")}
              </Typography>
            </Grid>
            <Grid display="flex" justifyItems="flex-end">
              <MoreMenu>
                <MenuItem>
                  <DashboardChartDetailLink noData={noData} chart={chart} />
                </MenuItem>
                <MenuItem onClick={onClickFormula} disabled={NO_FORMULA_CHARTS.includes(chart)}>
                  <CalculateIcon sx={{ mr: 1 }} />
                  {t("view.formula")}
                </MenuItem>
              </MoreMenu>
            </Grid>
          </Grid>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {noData ? (
            <Box mt={-4} display="flex" justifyContent="center" alignItems="center">
              <Typography color={(theme) => theme.palette.grey[500]}>{t("no-data")}</Typography>
            </Box>
          ) : (
            <DashboardTable
              columns={
                Array.isArray(useChartsQuery.data)
                  ? useChartsQuery.data[0].datasets.map(({ index }) => index)
                  : useChartsQuery.data.datasets.map(({ index }) => index)
              }
              parentRows={chartInstance.getAggregateTypeRows(t, useChartsQuery.data, displaySwitch)}
              unitCaption={chartInstance.unitCaption}
              industryData={useIndustryCompaniesCountQuery.data}
              allIndustryData={useAllIndustryCompaniesCountQuery.data}
              notes={chartInstance.notes}
            />
          )}
        </StyledAccordionDetails>
        <DashboardFormula open={formulaOpen} onClose={() => setFormulaOpen(false)} chart={chart} />
      </StyledAccordion>
    );
  };

  return type === "graph" ? renderGraph() : renderTable();
};

export const DashboardChartInBoard = memo(_DashboardChartInBoard) as typeof _DashboardChartInBoard;
