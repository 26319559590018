import { Chart } from "../../../types";

export const CHART_HEIGHT = 640;
export const CHART_HEIGHT_IN_BOARD = 320;
export const CHART_MARGIN_TOP = 32;
export const CHART_MARGIN_TOP_IN_BOARD = 32;
export const CHART_MARGIN_BOTTOM = 8;

export const GROUP_CHARTS_DICT: {
  [group in string]: Chart[];
} = {
  gender_pay_gap: ["gender_pay_gap"],
  female_manager_ratio: ["female_manager_ratio"],
  male_childcare_leave_rate: [
    "male_childcare_leave_rate",
    "male_childcare_leave_rate_by_employee_type",
    "male_childcare_leave_or_childcare_purpose_leave_rate",
  ],
  gender_composition: [
    "gender_composition_of_all_employee",
    "gender_composition_of_regular_employee",
    "gender_composition_of_non_regular_employee",
  ],
  employee_composition: ["full_time_employee_headcount_by_gender", "full_time_employee_headcount_by_employee_type"],
  span_of_control: [
    "span_of_control",
    "province_span_of_control_of_executive",
    "province_span_of_control_of_executive_officer",
    "province_span_of_control_of_general_manager",
    "province_span_of_control_of_division_manager",
    "province_span_of_control_of_section_manager",
    "direct_span_of_control_of_executive",
    "direct_span_of_control_of_executive_officer",
    "direct_span_of_control_of_general_manager",
    "direct_span_of_control_of_division_manager",
    "direct_span_of_control_of_section_manager",
  ],
  average_age: ["average_age_of_all_employee_by_gender", "average_age_of_regular_employee_by_gender"],
  age_distribution: ["age_distribution_of_regular_employee_by_gender", "age_distribution_of_manager_by_gender"],
  service_year: [
    "average_service_year_of_all_employee_by_gender",
    "average_service_year_of_regular_employee_by_gender",
    "average_service_year_of_non_regular_employee_by_gender",
    "full_time_employee_average_service_year_by_gender",
    "gender_average_service_year_gap_of_all_employee",
    "gender_average_service_year_gap_of_regular_employee",
    "gender_average_service_year_gap_of_non_regular_employee",
    "full_time_employee_gender_average_service_year_gap",
  ],
  employed_retention_rate: [
    "annual_new_graduate_employed_three_year_retention_rate_of_college_graduate_by_gender",
    "annual_new_graduate_employed_five_year_retention_rate_of_college_graduate_by_gender",
    "annual_mid_career_employed_three_year_retention_rate_by_gender",
    "annual_mid_career_employed_five_year_retention_rate_by_gender",
  ],
  annual_employed_composition: [
    "annual_employed_composition_of_all_employee_by_gender",
    "annual_employed_composition_of_regular_employee_by_gender",
    "annual_employed_composition_of_non_regular_employee_by_gender",
    "annual_employed_composition_of_regular_employee_by_employment_type",
    "annual_new_graduate_employed_composition_of_regular_employee_by_gender",
    "annual_mid_career_employed_composition_of_regular_employee_by_gender",
  ],
  mid_career_employed: [
    "mid_career_employed_headcount_and_rate_of_all_employee",
    "mid_career_employed_headcount_and_rate_of_manager",
    "mid_career_employed_executive_headcount_and_rate",
  ],
  separated: [
    "separated_headcount_and_rate_of_regular_employee_by_gender",
    "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender",
  ],
  executive_composition: ["executive_gender_composition"],
  childcare_leave: [
    "childcare_leave_rate_of_all_employee_by_gender",
    "childcare_leave_rate_of_regular_employee_by_gender",
    "childcare_leave_rate_of_non_regular_employee_by_gender",
    "average_childcare_leave_taken_days_of_all_employee_by_gender",
    "average_childcare_leave_taken_days_of_regular_employee_by_gender",
    "average_childcare_leave_taken_days_of_non_regular_employee_by_gender",
    "male_childcare_leave_taken_day_distribution",
  ],
  foreign_employee: [
    "foreign_employee_headcount_by_nationality",
    "foreign_employee_headcount_by_gender",
    "foreign_employee_headcount_and_rate_of_all_employee",
    "foreign_employee_headcount_and_rate_of_manager",
    "foreign_new_graduate_employed_headcount_and_rate",
    "foreign_executive_headcount_and_rate",
  ],
  disabled_employment: ["disabled_employment_headcount_and_rate", "disabled_employment_shortage_headcount"],
  over_scheduled_working_hours: [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee",
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager",
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager",
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee",
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager",
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager",
  ],
  paid_leave_consumed_days: [
    "average_annual_paid_leave_consumed_days_and_rate_of_all_employee",
    "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee",
    "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee",
  ],
  annual_salary: ["average_annual_salary_of_regular_employee_by_gender"],
  annual_bonus: [
    "average_annual_bonus_of_regular_employee_by_gender",
    "average_annual_bonus_of_regular_employee_by_grade_type",
  ],
  salary_increase_rate: ["salary_increase_rate_of_regular_employee_by_management_type"],
  discipline: ["discipline_count_by_discipline_type"],
};

/*
 * 指標で利用するTYPES
 */
export const AGGREGATE_TYPES = ["our_company", "industry_average", "all_industry_average"] as const;
// 下記のリストは、[male, female, all]、[regular, non_regular, all]の順で定義すること
// また利用時は以下のルールにしたがって利用すること
// 表の場合: リストをそのままの順番で利用
// 折れ線グラフ: リストをそのままの順番で利用
// 積み上げグラフ: リストを逆順にして利用（ex: EMPLOYEE_TYPES.toReversed()）
// 積み上げグラフ+折れ線グラフ: リストを逆順にして利用（ex: EMPLOYEE_TYPES.toReversed()）
export const EMPLOYEE_TYPES = ["regular", "non_regular", "all"] as const;
export const EMPLOYMENT_TYPES = ["new_graduate", "mid_career", "other", "all"] as const;
export const ANNUAL_EMPLOYED_COMPOSITION_HEADCOUNT_TYPES = [
  "new_graduate_headcount",
  "mid_career_headcount",
  "other_headcount",
  "all_headcount",
] as const;
export const ANNUAL_EMPLOYED_COMPOSITION_RATIO_TYPES = [
  "new_graduate_ratio",
  "mid_career_ratio",
  "other_ratio",
] as const;
export const BINARY_GENDER_TYPES = ["male", "female"] as const;
export const GENDER_TYPES = [...BINARY_GENDER_TYPES, "all"] as const;
export const BINARY_GENDER_HEADCOUNT_TYPES = ["male_headcount", "female_headcount"] as const;
export const GENDER_HEADCOUNT_TYPES = [...BINARY_GENDER_HEADCOUNT_TYPES, "all_headcount"] as const;
export const GENDER_RATIO_TYPES = ["male_ratio", "female_ratio"] as const;
export const GENDER_RATE_TYPES = ["male_rate", "female_rate", "all_rate"] as const;
export const GRADE_TYPES = ["division_manager", "section_manager", "staff"] as const;
export const MANAGEMENT_TYPES = ["manager", "non_manager", "all"] as const;

export const PHYSICAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "severe_physical_disabled_employee_working_over_30h_headcount",
  "new_employed_severe_physical_disabled_employee_working_over_30h_headcount",
  "non_severe_physical_disabled_employee_working_over_30h_headcount",
  "new_employed_non_severe_physical_disabled_employee_working_over_30h_headcount",
  "severe_physical_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_severe_physical_disabled_employee_working_20h_to_30h_headcount",
  "severe_physical_disabled_employee_working_10h_to_20h_headcount",
  "new_employed_severe_physical_disabled_employee_working_10h_to_20h_headcount",
  "non_severe_physical_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_non_severe_physical_disabled_employee_working_20h_to_30h_headcount",
  "physical_disabled_employee_headcount",
  "new_employed_physical_disabled_employee_headcount",
];

export const INTELLECTUAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "severe_intellectual_disabled_employee_working_over_30h_headcount",
  "new_employed_severe_intellectual_disabled_employee_working_over_30h_headcount",
  "non_severe_intellectual_disabled_employee_working_over_30h_headcount",
  "new_employed_non_severe_intellectual_disabled_employee_working_over_30h_headcount",
  "severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
  "severe_intellectual_disabled_employee_working_10h_to_20h_headcount",
  "new_employed_severe_intellectual_disabled_employee_working_10h_to_20h_headcount",
  "non_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_non_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
  "intellectual_disabled_employee_headcount",
  "new_employed_intellectual_disabled_employee_headcount",
];

export const MENTAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "mental_disabled_employee_working_over_30h_headcount",
  "new_employed_mental_disabled_employee_working_over_30h_headcount",
  "mental_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_mental_disabled_employee_working_20h_to_30h_headcount",
  "mental_disabled_employee_working_10h_to_20h_headcount",
  "new_employed_mental_disabled_employee_working_10h_to_20h_headcount",
  "mental_disabled_employee_headcount",
  "new_employed_mental_disabled_employee_headcount",
] as const;

export const OTHER_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "visual_disabled_employee_headcount",
  "auditory_balance_disabled_employee_headcount",
  "speech_language_mastication_disabled_employee_headcount",
  "physical_impaired_employee_headcount",
  "internal_disabled_employee_headcount",
] as const;

export const TOTAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "disabled_employee_headcount",
  "new_employed_disabled_employee_headcount",
];

export const SINGLE_YEAR_CHARTS = [
  "age_distribution_of_regular_employee_by_gender",
  "age_distribution_of_manager_by_gender",
  "male_childcare_leave_taken_day_distribution",
];

export const NO_FORMULA_CHARTS = [
  "full_time_employee_headcount_by_gender",
  "full_time_employee_headcount_by_employee_type",
  "age_distribution_of_regular_employee_by_gender",
  "age_distribution_of_manager_by_gender",
  "foreign_employee_headcount_by_nationality",
  "foreign_employee_headcount_by_gender",
  "discipline_count_by_discipline_type",
];

export const DISABLED_EMPLOYMENT_RATE_TYPES = ["disabled_employment_rate"] as const;
export const DISCIPLINE_TYPES = [
  "disciplinary_dismissal",
  "recommended_retirement",
  "demotion",
  "suspension_from_work",
  "pay_cut",
  "reprimand",
  "warning",
  "all",
] as const;

// 在籍会社のみを表示するチャート
export const COMPANY_TYPE_FILTER_ONLY_CURRENT_COMPANY_CHART_LIST: Chart[] = [
  "manager_rate",
  "span_of_control",
  "province_span_of_control_of_executive",
  "province_span_of_control_of_executive_officer",
  "province_span_of_control_of_general_manager",
  "province_span_of_control_of_division_manager",
  "province_span_of_control_of_section_manager",
  "direct_span_of_control_of_executive",
  "direct_span_of_control_of_executive_officer",
  "direct_span_of_control_of_general_manager",
  "direct_span_of_control_of_division_manager",
  "direct_span_of_control_of_section_manager",
];

/*
 * 算出根拠で利用するTYPES
 */
export const EMPLOYEE_GENDER_TYPES = [
  "regular_male",
  "regular_female",
  "non_regular_male",
  "non_regular_female",
  "all_male",
  "all_female",
] as const;

export const OUR_COMPANY_COLORS = ["#007CC0", "#008ED4", "#04A1E8", "#08AFF7", "#52C8F8", "#83D8FB", "#B4E7FC"];
export const INDUSTRY_AVERAGE_COLORS = ["#E45327", "#F25A2C", "#FD6030", "#FD774D", "#FD8F6D", "#FDAE97", "#FDCEBF"];
export const ALL_INDUSTRY_AVERAGE_COLORS = [
  "#FB9D15",
  "#FCAF16",
  "#FCBD1B",
  "#FDC630",
  "#FED253",
  "#FEDE84",
  "#FEEBB4",
];
export const OUR_COMPANY_LINE_WITH_COLUMN_COLORS = ["#95CEFF", "#BCE0FF", "#E4F3FE"];
export const INDUSTRY_AVERAGE_LINE_WITH_COLUMN_COLORS = ["#FFA88C", "#FFC9B8", "#FAE1D9"];
export const ALL_INDUSTRY_AVERAGE_LINE_WITH_COLUMN_COLORS = ["#FFE0B1", "#FFF0D9", "#F2E6D5"];
